import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`;

const Layout = ({ children }) => {

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => { 
        let lwcAssets = [];

        return (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta http-equiv="Cache-control" content="no-store" />
            <meta property="og:title" content={siteMetadata.title} />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            <script key='lwc1' src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script key='lwc2' src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <script key='lwc3' src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <link key='lwc4' href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link key='lwc5' href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />

          </Helmet>

          <div id="prtnr-site" className="prtnr-site">
            {children}
          </div>
        </>
      )}}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
