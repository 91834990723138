import React from 'react'
import Layout from '../layouts/Layout'
import I18n from '../utils/I18n'
import Companies from '../components/Companies'

const HomeTemplate = data => {
  const {pageContext: {lang} } = data
  const i18n = new I18n({ lang })

  return (
    <Layout>
      <div>
        <tm-page>
          <div>
            <th-element-loader element="tm-page" style={{height: '100vh'}} />
          </div>

          <div>
            <tm-hero-a 
              background="light" 
              img-src="https://developer.salesforce.com/resources2/trailblazer-connect/director/hero.png?v=2"
              img-alt={i18n.text('header_image_alt')} 
              title={i18n.text('header_title')} 
              subtitle={i18n.text('header_subtitle')}
            />
          </div>

          <tm-section-a title={i18n.text('directory_title')}>
            <Companies lang={lang} />
          </tm-section-a>

          <div>
            <tm-end-cap-a 
              background="dark" 
              img-src="https://developer.salesforce.com/resources2/trailblazer-connect/director/end-cap-img.png" 
              img-alt="Become a Workforce Partner" 
              title="Become a Workforce Partner"
              subtitle="Be part of a global movement to develop the next generation of Trailblazers." 
              cta-href={`/${lang}/trailblazerconnect/workforce-partners/apply`}
              cta-label="Apply Here" 
            />
          </div>
        </tm-page>
      </div>

    </Layout>
  )
}

export default HomeTemplate;
