import React, { useState, useEffect, useCallback } from 'react'
import I18n from '../utils/I18n'
import Translations from '../utils/Translations'
import StringUtils from '../utils/StringUtils'

const Companies = (props) => {
    const { lang } = props

    // MOVE THIS TO A ENV VARIABLE
    const drmEndpoint = process.env.GATSBY_DRM_ENDPOINT
    const imageBaseUrl = process.env.GATSBY_S3_BASE_PATH
    ////

    const i18n = new I18n({ lang })

    const typeDescriptions = {
        'community': i18n.text('combo_type_community_description'),
        'higher_education': i18n.text('combo_type_higher_education_description'),
        'hire_train_deploy': i18n.text('combo_type_hire_train_deploy_description'),
        'salesforce_led': i18n.text('combo_type_salesforce_led_description')
    }


    // --- STATE ---
    const [requestData, setRequestData] = useState(true)
    const [shouldPrepareFields, setShouldPrepareFields] = useState(true)
    const [loading, setLoading] = useState(true)
    const [allItems, setAllItems] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [filterValues, setFilterValues] = useState(null)
    const [currentFilters, setCurrentFilters] = useState([])
    const [typeDescription, setTypeDescription] = useState('')
    // --- END OF STATE ---


    // --- EFFECTS ---
    useEffect(() => {
        if(requestData) {
            handleCardLoad()
        }
    })

    useEffect(() => {
        if(allItems.length > 0 && filterValues && shouldPrepareFields) {
            setShouldPrepareFields(false)
            prepareFields()
        }
    }, [allItems, filterValues])
    // --- END OF EFFECTS ---


    const handleCardLoad = () => {
        setRequestData(false)
        fetch(drmEndpoint).then(res => res.json()).then(result => {
            let items = []

            result.map(item => {
                if(item.hasOwnProperty('RequestResult') && item['RequestResult'] === 'Success' ){
                    items.push(item)
                }
            })

            const newFilterValues = createFilterValues(items)
            items = randomizeItems(shortDescriptions(items))

            setFilterValues(newFilterValues)
            setAllItems(items)
            setCurrentItems(items)
            setLoading(false)
        })
    }

    const prepareFields = () => {
        // FILL TDS_COMBOBOX OPTIONS WHEN THEY ARE READY ON PAGE
        customElements.whenDefined("tds-combobox").then(() => {
            setComboOptions()

            document.querySelectorAll("tds-combobox").forEach( input => {
                if (input.dataset && !Object.hasOwnProperty.call(input.dataset, 'onChangeBinded')) {
                    input.dataset.onChangeBinded = true
                    // Add Event Listener
                    input.addEventListener('change', handleComboboxOnChange)
                }
            })
        })

        customElements.whenDefined("lwc-tm-card").then(() => {
            const cards = document.querySelector("tm-card-grid-a").shadowRoot.querySelectorAll("lwc-tm-card")
            cards.forEach(card => {
                //card.shadowRoot.querySelector(".image-container").setAttribute('style', 'align-self: initial;')
                //card.shadowRoot.querySelector(".image-container img").setAttribute('style', 'max-width: calc(80% - (var(--tds-spacing-6))*2)')
            })
        })
    }

    const shortDescriptions = (items) => {
        return items.map(item => {
            item.description = item.description && StringUtils.cutTextAt(item.description, 200)
            return item
        }) 
    }

    const randomizeItems = (items) => {
        let randomizedArray = items
        let newItems = []
        
        // Randomize array in-place using Durstenfeld shuffle algorithm
        for (let i = randomizedArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            const temp = randomizedArray[i]
            randomizedArray[i] = randomizedArray[j]
            randomizedArray[j] = temp
            newItems = randomizedArray
        }

        return newItems
    }

    const createFilterValues = (data) => {
        let filterValues = {}

        data.forEach(item => {
            const filterData = item.filterData[0]
            
            Object.keys(filterData).forEach(key => {
                if(!Object.hasOwnProperty.call(filterValues, key)) {
                    filterValues[key] = []
                }

                if(filterData[key]) {
                    const values = filterData[key].split(';')
                    filterValues[key] = filterValues[key].concat(values)
                    filterValues[key] = Array.from(new Set(filterValues[key]))
                }
            })
        })

        return filterValues
    }

    const setComboOptions = () => {
        let options = {}
        
        Object.keys(filterValues).forEach( key => {
            filterValues[key].forEach( val => {
                if(!Object.hasOwnProperty.call(options, key)) {
                    options[key] = []
                }

                const labelName = (`filter_${key}_${val}`).toLowerCase().replace(/[ -]/g, '_')
                const labelValue = i18n.text(labelName)

                options[key].push({label: val, value: val})
                options[key] = options[key].sort((a, b) => (a.label > b.label) ? 1 : -1)
                
                if(key === 'program_length') {
                    options[key] = options[key].sort((a, b) => a.value === '1+_year' ? 1 : ((a.label > b.label && b.value !== '1+_year') ? 1 : -1))
                }
            })
        })

        Object.keys(options).forEach( combo => {
            const comboEl = document.querySelector(`#combo_${combo}`)
            if(comboEl) {
                comboEl.options = Object.hasOwnProperty.call(options, combo) ? options[combo] : []
                comboEl.value = ''
            }
            
        })
    }


     // --- CALLBACKS ---
     const handleComboboxOnChange = useCallback( e => {
        e.preventDefault()
        const val = e.nativeEvent ? e.nativeEvent.target.value : e.detail
        const name = e.target.dataset.name
        
        if (val === "") {
            removeFilter(name)
        } else {
            addOrChangeFilter(name, val)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItems])
    // --- END OF CALLBACKS ---



    const filterItems = ( items, newCurrentFilters = null ) => {
        let newItems = items

        if ( newCurrentFilters !== null ) {
            if ( newCurrentFilters.length > 0 ) {
                newCurrentFilters.forEach( f => {
                    newItems = newItems.filter(c => c.filterData[0][f.name] === f.value || c.filterData[0][f.name] !== null && c.filterData[0][f.name].includes(f.value))
                    newItems = newItems.sort((a, b) => (a.title > b.title) ? 1 : -1)
                })
            
            } else {
                newItems = randomizeItems(newItems)
            }
        
        } else {
            newItems = randomizeItems(allItems)
        }

        return newItems
    }

    const getMessageBasedOnType = (currentFilters) => {
        const typeFilter = currentFilters.filter(el => el.name === 'type')
        const typeVal = typeFilter.length > 0 ? typeFilter[0].value : ''

        return typeVal !== '' ? typeDescriptions[typeVal] : ''
    }

    const addOrChangeFilter = (key, value) => {
        let newCurrentFilters = currentFilters
        
        if( currentFilters.length !== 0 && newCurrentFilters.filter(el => el.name === key).length > 0) {
            // UPDATE CURRENT VALUE
            newCurrentFilters.forEach(filter => {
                if(filter.name === key) {
                    filter.value = value
                }
            })
        
        } else {
            // ADD NEW FILTER
            newCurrentFilters.push({
                "name": key,
                "value": value
            })
        }

        setCurrentItems(filterItems(randomizeItems(allItems), newCurrentFilters))
        setCurrentFilters(newCurrentFilters)
        setTypeDescription(getMessageBasedOnType(newCurrentFilters))
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }

    const removeFilter = (key) => {
        let newCurrentFilters = currentFilters

        if(currentFilters.filter(el => el.name === key).length > 0) {
            newCurrentFilters = currentFilters.filter(el => el.name !== key)
        }

        const newItems = filterItems(randomizeItems(allItems), newCurrentFilters)
        const typeDescription = getMessageBasedOnType(newCurrentFilters)

        setCurrentItems(newItems)
        setCurrentFilters(newCurrentFilters)
        setTypeDescription(typeDescription)
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }

    const clearFilters = () => {

        setCurrentItems(randomizeItems(allItems))
        setCurrentFilters([])
        setTypeDescription('')
        setLoading(true)

        setComboOptions()

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }

    return (
        <div>
            <div id="combos-container" className="slds-container_x-large slds-container_center">
                <div className="slds-grid slds-wrap slds-grid_align-center slds-grid_vertical-align-center">
                    <div className="slds-size_1-of-1 slds-small-size_1-of-2 slds-medium-size_1-of-3 slds-large-size_1-of-5 slds-p-around_medium">
                        <tds-combobox id="combo_region" data-name="region" label={i18n.text('combo_region_label')} placeholder={i18n.text('combo_placeholder')}></tds-combobox>
                    </div>
                    <div className="slds-size_1-of-1 slds-small-size_1-of-2 slds-medium-size_1-of-3 slds-large-size_1-of-5 slds-p-around_medium">
                        <tds-combobox id="combo_type" data-name="type" label={i18n.text('combo_type_label')} placeholder={i18n.text('combo_placeholder')}></tds-combobox>
                    </div>
                    <div className="slds-size_1-of-1 slds-small-size_1-of-2 slds-medium-size_1-of-3 slds-large-size_1-of-5 slds-p-around_medium">
                        <tds-combobox id="combo_career_path" data-name="career_path" label={i18n.text('combo_career_path_label')} placeholder={i18n.text('combo_placeholder')}></tds-combobox>
                    </div>
                    <div className="slds-size_1-of-1 slds-small-size_1-of-2 slds-medium-size_1-of-3 slds-large-size_1-of-5 slds-p-around_medium">
                        <tds-combobox id="combo_program_length" data-name="program_length" label={i18n.text('combo_program_length_label')} placeholder={i18n.text('combo_placeholder')}></tds-combobox>
                    </div>
                    <div className=" slds-size_1-of-1 slds-small-size_1-of-2 slds-medium-size_1-of-3 slds-large-size_1-of-5 slds-p-around_medium slds-m-top_x-large slds-text-align_center custom-medium-text-align_left">
                        <button className="link-style" onClick={clearFilters}>
                            {i18n.text('clear_all_filters')}
                        </button>
                    </div>
                </div>
            </div>

            <div id="typeDescription" className="slds-container_center slds-container_medium slds-text-align_center slds-m-top_medium">
                {typeDescription}
            </div>

            <div id="cards">
                {currentItems.length === 0 && (
                    <tm-footnote>
                        <p>{i18n.text('no_items_message')}</p>
                    </tm-footnote>
                )}

                {currentItems.length > 0 && loading && (
                    <center className="slds-p-top_xx-large H(550)">
                        <tds-spinner size="large"></tds-spinner>
                    </center>
                )}

                {currentItems.length > 0 && !loading && (
                    <tm-section-a>
                        <tm-card-grid-a 
                            key="grid-a" 
                            img-type="left" 
                            class={
                                currentItems.length === 1 
                                    ? 'custom-container-cards_small' 
                                    : currentItems.length === 2 
                                        ? 'custom-container-cards_medium' 
                                        : 'custom-container-cards_large'}>
                            
                            {currentItems.map( (item, index) => {
                                return (
                                    <tm-grid-item 
                                        key={`item-${index}`}
                                        img-src={`${imageBaseUrl}/${item.imgSrc}`}
                                        img-alt={item.imgAlt}
                                        title={item.title}
                                        description={item.description}
                                        cta-title={i18n.text('card_cta_title')}
                                        cta-href={item.ctaHref}
                                        cta-target={item.ctaTarget}>
                                    </tm-grid-item>
                                )
                            })}
                        </tm-card-grid-a>
                    </tm-section-a>
                )}
            </div>
        </div>
    )
}

export default Companies
