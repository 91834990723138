// using CommonJS export because function is used in gatsby-node.js file and runs during node build
module.exports = {
  camelize: (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  },
  dashJoin: (str) => {
    return str.toLowerCase().replace(/(\s+)/g, '-');
  },
  sanitizeHtml: (str) => {
    return str.replace(/</g,"&lt;").replace(/>/g,"&gt;");
  },
  cutTextAt: (txt, charLimit) => {
    let newText = "";
    const endText = "[...]";
    let finish = false;
    
    if (txt.length > charLimit) {
      const words = txt.split(' ');

      words.forEach(word => {
        if(!finish) {
          if(newText.length + 1 + word.length + 1 + endText.length < charLimit) {
            newText = newText !== "" ? newText+" "+word : word;
          } else {
            finish = true;
            newText = newText + " "+endText;
          }
        }
      });

    } else {
      newText = txt;
    }
    
    return newText;
  }
}
